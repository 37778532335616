import React from 'react'
import logo from '../../assets/logo-transparent.png'

import { SignInButton } from './SignInButton'

import './LoginPage.css'

export const LoginPage = (props) => {
    return (
        <div className="login-page">
            <img src={logo} className="App-logo" alt="logo" />
            <h2>
                <center>Welkom bij de decla-app!</center>
            </h2>
            <SignInButton />
            <br />
            <br />
            {props.children}
        </div>
    )
}
