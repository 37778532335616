import React from 'react'
import './Modal.css'

const Modal = ({ isOpen, title, message, onConfirm, onCancel }) => {
    if (!isOpen) return null

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onCancel()
        }
    }

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal">
                <p>{message}</p>
                <div className="modal-buttons">
                    {onConfirm ? (
                        <>
                            <button onClick={onConfirm}>Ja</button>
                            <button onClick={onCancel}>Nee</button>
                        </>
                    ) : (
                        <button onClick={onCancel}>OK</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Modal
