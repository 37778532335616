import React from 'react'
import './SelectedFiles.css'

const SelectedFiles = ({ selectedFiles, handleFileDelete }) => (
    <div className="selected-files">
        <label>Geselecteerde bestanden (pdf of img):</label>
        <ul>
            {selectedFiles.map((file, index) => (
                <li key={index} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <span
                        className="delete-file"
                        onClick={() => handleFileDelete(index)}
                    >
                        &times;
                    </span>
                </li>
            ))}
        </ul>
    </div>
)

export default SelectedFiles
