import React from 'react'

const FileInputField = ({ id, fileInput, handleFileChange }) => (
    <div className="input-group">
        <input
            type="file"
            id={id}
            ref={fileInput}
            multiple
            accept=".pdf, image/jpeg, image/png"
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />
        <label htmlFor={id} className="file-input-label">
            Kies bestand(en)
        </label>
    </div>
)

export default FileInputField
