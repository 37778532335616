import jspdf from 'jspdf'
import 'jspdf-autotable'

const generatePDF = (
    name,
    bankAccountNumber,
    posts,
    categorySelectRefs,
    dateToday,
    totalAmount
) => {
    const doc = new jspdf({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
    })

    doc.setFontSize(10)
    doc.text(`Naam declarant: ${name}`, 15, 10)
    doc.text(`Bankrekeningnummer: ${bankAccountNumber}`, 15, 15)
    doc.text(`Declaratienummer: ${name}-${dateToday}`, 15, 20)
    doc.text(`Declaratie omschrijving: ${name}-${dateToday}`, 15, 25)
    doc.text(
        `Totaalbedrag van deze declaratie: € ${totalAmount.toLocaleString('nl-NL')}`,
        15,
        30
    )

    const headers = [
        [
            'Datum',
            'Omschrijving',
            'Categoriecode',
            'Categorienaam',
            'Bedrag (excl. BTW)',
            'BTW bedrag',
            'Totaalbedrag',
        ],
    ]
    const data = posts.map((post, index) => {
        const categoryName =
            categorySelectRefs.current[index].current.options[
                categorySelectRefs.current[index].current.selectedIndex
            ].text
        const totalAmount =
            Number(post.totalAmountWithoutTaxes) + Number(post.taxAmount)
        return [
            dateToday,
            post.description,
            post.category,
            categoryName.substring(0, 20),
            '€ ' + Number(post.totalAmountWithoutTaxes).toLocaleString('nl-NL'),
            '€ ' + Number(post.taxAmount).toLocaleString('nl-NL'),
            '€ ' + totalAmount.toLocaleString('nl-NL'),
        ]
    })

    doc.autoTable({
        head: headers,
        body: data,
        startY: 35,
        styles: { cellWidth: 'wrap', cellPadding: 1, fontSize: 8 },
        tableWidth: 'auto',
        setFontSize: 10,
    })

    const pdfData = doc.output('blob')
    return pdfData
}

export default generatePDF
