import axios from 'axios'

const sendEmail = async (
    name,
    bankAccountNumber,
    totalAmount,
    dateToday,
    files,
    pdfData
) => {
    const recipient =
        process.env.NODE_ENV === 'production'
            ? 'rp@newspark.nl'
            : 'js@newspark.nl'

    const form = new FormData()
    form.append('from', 'Decla App <declaapp@newspark.nl>')
    form.append('to', `newspark <${recipient}>`)
    form.append('subject', `Declaratie van ${name} - ${dateToday}`)
    form.append(
        'text',
        'Beste Rowald,\n\n' +
            'Hier weer een declaratie gemaakt met de decla-app, opgesteld door ' +
            name +
            ' van Newspark.\n\n' +
            'Het totaalbedrag van deze declaratie is: € ' +
            totalAmount.toLocaleString('nl-NL') +
            '.\nDit kan worden uitgekeerd naar rekeningnummer: ' +
            bankAccountNumber +
            '\n\nVragen? neem contact op met ' +
            name +
            ' of met de bouwers van de decla-app.\n\nMvg,\n\nDecla-app'
    )

    Array.from(files).forEach((file) => {
        form.append('attachment', file)
    })

    const fileName = `Declaratie van ${name} - ${dateToday}.pdf`
    form.append('attachment', pdfData, fileName)

    try {
        const response = await axios.post(
            'https://api.mailgun.net/v3/sandbox1d23fd0adedd448b8a08a0223004401a.mailgun.org/messages',
            form,
            {
                auth: {
                    username: 'api',
                    password:
                        '885d08aa32450a327ecd0321670756ac-4b670513-d97c74e8',
                },
            }
        )
        return response
    } catch (error) {
        console.error('There was an error sending the email', error)
        alert(error.message)
    }
}

export default sendEmail
