import React, { useState, useRef, useEffect } from 'react'
import './DeclaForm.css'
import generatePDF from '../../utils/pdfHandler'
import sendEmail from '../../utils/emailHandler'
import 'jspdf-autotable'
import { categoryOptions } from '../../data/categories'
import FileInputField from '../InputField/FileInputField'
import SelectedFiles from '../InputField/SelectedFiles'
import InputField from '../InputField/InputField'
import SelectField from '../InputField/SelectField'
import useGraphData from '../../hooks/graph'
import Modal from '../Modal/Modal'

function DeclaForm() {
    const { userName } = useGraphData()
    const [name, setName] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState('')
    const [posts, setPosts] = useState([
        {
            description: '',
            category: '',
            totalAmountWithoutTaxes: '',
            taxAmount: '',
        },
    ])
    const fileInput = useRef()
    const [selectedFiles, setSelectedFiles] = useState([])
    const categorySelectRefs = useRef([
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ])
    const newPostRef = useRef(null)

    const dateToday = new Date().toLocaleDateString('nl-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [modalCallback, setModalCallback] = useState(null)

    useEffect(() => {
        if (userName) {
            setName(userName)
        }
    }, [userName])

    useEffect(() => {
        const savedBankAccountNumber = localStorage.getItem('bankAccountNumber')
        if (savedBankAccountNumber) {
            setBankAccountNumber(savedBankAccountNumber)
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('bankAccountNumber', bankAccountNumber)
    }, [bankAccountNumber])

    const handlePostChange = (index, field, value) => {
        const newPosts = [...posts]
        newPosts[index][field] = value
        setPosts(newPosts)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        //let newTab = null

        if (selectedFiles.length === 0) {
            setModalMessage('Upload alstublieft minstens één bestand.')
            setModalCallback(null)
            setIsModalOpen(true)
            return
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i]
            if (post.category !== '4505' && post.taxAmount === '') {
                setModalMessage(
                    `Het BTW bedrag van post ${i + 1} is niet ingevuld. Wilt u toch doorgaan?`
                )
                setModalCallback(() => () => proceedWithSubmission())
                setIsModalOpen(true)
                return
            }
        }

        proceedWithSubmission()
    }

    const proceedWithSubmission = async () => {
        let newTab = null

        const totalAmount = posts.reduce(
            (acc, post) =>
                acc +
                Number(post.totalAmountWithoutTaxes) +
                Number(post.taxAmount),
            0
        )

        const files = fileInput.current.files

        setModalMessage('Wilt u een kopie van de PDF openen?')
        setModalCallback(() => () => {
            newTab = window.open('', '_blank')
            generateAndSendPDF(newTab, totalAmount, files)
        })
        setIsModalOpen(true)
    }

    const generateAndSendPDF = async (newTab, totalAmount, files) => {
        const pdfData = generatePDF(
            name,
            bankAccountNumber,
            posts,
            categorySelectRefs,
            dateToday,
            totalAmount
        )

        if (newTab) {
            const url = URL.createObjectURL(pdfData)
            newTab.location.href = url
        }

        const response = await sendEmail(
            name,
            bankAccountNumber,
            totalAmount,
            dateToday,
            files,
            pdfData
        )

        if (response) {
            setPosts([
                {
                    description: '',
                    category: '',
                    totalAmountWithoutTaxes: '',
                    taxAmount: '',
                },
            ])
            setSelectedFiles([])
            setIsModalOpen(false)
        }
    }

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files)
        const validTypes = ['application/pdf', 'image/jpeg', 'image/png']

        const invalidFiles = files.filter(
            (file) => !validTypes.includes(file.type)
        )
        if (invalidFiles.length > 0) {
            setModalMessage(
                'Upload alstjeblieft alleen pdf of image-bestanden (JPEG, PNG).'
            )
            setModalCallback(null)
            setIsModalOpen(true)
            return
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...files])
    }

    const handleFileDelete = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    }

    const addPost = () => {
        setPosts((prevPosts) => {
            const newPosts = [
                ...prevPosts,
                {
                    id: prevPosts.length + 1,
                    totalAmountWithoutTaxes: '',
                    taxAmount: '',
                },
            ]
            return newPosts
        })
    }

    useEffect(() => {
        if (newPostRef.current) {
            newPostRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [posts])

    const removePost = () => {
        setPosts(posts.slice(0, -1))
    }

    return (
        <div>
            <h2>Declaratie Formulier</h2>
            <form onSubmit={handleSubmit}>
                <div class="form-section">
                    <InputField
                        label="Naam declarant"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <InputField
                        label="Bankrekeningnummer"
                        type="text"
                        id="bankAccountNumber"
                        value={bankAccountNumber}
                        onChange={(e) => setBankAccountNumber(e.target.value)}
                        required
                    />
                    <FileInputField
                        id="fileInput"
                        fileInput={fileInput}
                        handleFileChange={handleFileChange}
                    />
                    <SelectedFiles
                        selectedFiles={selectedFiles}
                        handleFileDelete={handleFileDelete}
                    />
                </div>

                {posts.map((post, index) => (
                    <div
                        key={post.id}
                        ref={index === posts.length - 1 ? newPostRef : null}
                        class="form-section"
                    >
                        <h3>Post {index + 1}</h3>
                        <InputField
                            label="Omschrijving"
                            type="text"
                            id={`description${index}`}
                            value={post.description}
                            onChange={(e) =>
                                handlePostChange(
                                    index,
                                    'description',
                                    e.target.value
                                )
                            }
                            required
                            maxLength={50}
                        />
                        <SelectField
                            label="Categorie"
                            id={`category${index}`}
                            value={post.category}
                            onChange={(e) =>
                                handlePostChange(
                                    index,
                                    'category',
                                    e.target.value
                                )
                            }
                            required
                            options={categoryOptions}
                            ref={categorySelectRefs.current[index]}
                        />
                        <InputField
                            label="Bedrag (excl. BTW)"
                            type="text"
                            id={`totalAmountWithoutTaxes${index}`}
                            value={post.totalAmountWithoutTaxes
                                .toString()
                                .replace('.', ',')}
                            onChange={(e) => {
                                const formattedValue = e.target.value.replace(
                                    ',',
                                    '.'
                                )
                                handlePostChange(
                                    index,
                                    'totalAmountWithoutTaxes',
                                    formattedValue
                                )
                            }}
                            required
                            allowedChars={'0-9.,'}
                        />
                        <InputField
                            label="BTW bedrag"
                            type="text"
                            id={`taxAmount${index}`}
                            value={post.taxAmount.toString().replace('.', ',')}
                            onChange={(e) => {
                                const formattedValue = e.target.value.replace(
                                    ',',
                                    '.'
                                )
                                handlePostChange(
                                    index,
                                    'taxAmount',
                                    formattedValue
                                )
                            }}
                            allowedChars={'0-9.,'}
                        />
                    </div>
                ))}

                {posts.length > 1 && (
                    <button type="button" onClick={removePost}>
                        Haal laatste post weg
                    </button>
                )}
                {posts.length < 10 && (
                    <button type="button" onClick={addPost}>
                        Voeg extra post toe
                    </button>
                )}

                <button type="submit">Opsturen</button>
            </form>
            <Modal
                isOpen={isModalOpen}
                message={modalMessage}
                onConfirm={modalCallback}
                onCancel={() => setIsModalOpen(false)}
            />
        </div>
    )
}

export default DeclaForm
